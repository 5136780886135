import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { css } from 'styled-components'
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { css as cssHelper } from '@styled-system/css'

import {
  Box,
  Container,
  ButtonUnstyled,
  Icon,
  Paragraph,
  P,
  H1,
  H2,
  H5,
} from '@allied-solutions/affinity'
import { ArrowLeft } from '@allied-solutions/affinity-react-icons/dist/16px'

import { FancyList, Layout, Nav, SiteLayout, TagPill } from '../components'

const components = { FancyList }

const styles = css`
  margin-top: ${props => props.theme.space[20]};
  ${props => props.theme.typeStyles.bodyLarge}

  a {
    ${props => props.theme.typeStyles.bodyLarge}
    color: ${props => props.theme.colors.blue};
    text-decoration: underline;

    &:link,
    &:active,
    &:visited {
      ${props => props.theme.typeStyles.bodyLarge}
      color: ${props => props.theme.colors.blue};
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  /* li,
  p {
    ${props => props.theme.typeStyles.bodyLarge};
  } */

  h2 {
    ${props => props.theme.typeStyles.h4}
    margin-top: ${props => props.theme.space[24]};
    margin-bottom: ${props => props.theme.space[10]};
  }

  h3 {
    ${props => props.theme.typeStyles.h5}
    margin-top: ${props => props.theme.space[22]};
    margin-bottom: ${props => props.theme.space[8]};
  }

  h4 {
    ${props => props.theme.typeStyles.h6};
    margin-top: ${props => props.theme.space[20]};
    margin-bottom: ${props => props.theme.space[7]};
  }

  img {
    border-radius: ${props => props.theme.radii[4]};
    margin-top: ${props => props.theme.space[8]};
    margin-bottom: ${props => props.theme.space[8]};
  }

  h2,
  h3,
  h4 {
    + p > img {
      margin-top: 0;
    }
  }
`

const styles2 = css`
  h2 {
    ${props => props.theme.typeStyles.h6};
  }

  ul {
    padding-left: 14px;
  }

  ol {
    padding-left: 18px;
  }
`

const SupportArticle = ({
  pageContext: {
    frontmatter: { tag, title, metaTitle, metaDescription, slug },
    body,
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>{metaTitle || title}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
      </Helmet>
      <SiteLayout>
        <Box
          height="100px"
          width="100%"
          css={`
            ${props => props.theme.mediaQueries.lg} {
              background-color: white;
            }
            @media only screen and (min-width: 1380px) {
              background-color: transparent;
            }
          `}
        >
          <Nav underlineSupport liColor="gray1000" />
        </Box>

        <Container pt={4} px={8} mt={4} transition="margin .25s ease-in-out">
          <ButtonUnstyled
            id="SupportArticle__Button--back"
            onClick={() => navigate(-1)}
          >
            <Icon src={ArrowLeft} color="blue" mr={1} />
            <P mb={0} typeStyle="bodyMedium" textAlign="left" color="link">
              Back
            </P>
          </ButtonUnstyled>
        </Container>
        <Container
          pt={(9, 9, 18, 18)}
          pb={40}
          px={8}
          css={`
            max-width: 800px;
          `}
        >
          <Box textAlign="center">
            {slug !== 'privacy' && slug !== 'terms' ? (
              <Paragraph
                typeStyle="titleMedium"
                textAlign="center"
                fontWeight="black"
              >
                Support
              </Paragraph>
            ) : null}
            {slug === 'privacy' || slug === 'terms' ? (
              <H5 className="h5" typeStyle="h5" css={cssHelper({ mb: 7 })}>
                {title}
              </H5>
            ) : (
              <H2
                className="h2"
                typeStyle="h2"
                lineHeight="6"
                css={`
                  font-size: 1.8em;
                  margin-top: ${props => props.theme.space[7]};
                  text-wrap: balance;
                  ${props => props.theme.mediaQueries.sm} {
                    font-size: 2em;
                  }

                  ${props => props.theme.mediaQueries.md} {
                    font-size: 42px;
                  }
                `}
              >
                {title}
              </H2>
            )}
            {tag && <TagPill tagName={tag} mt={10} />}
          </Box>
          <Box css={slug === 'privacy' || slug === 'terms' ? styles2 : styles}>
            <MDXProvider components={components}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </Box>
        </Container>
      </SiteLayout>
    </Layout>
  )
}

export default SupportArticle
